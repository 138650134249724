import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { AdvancedSearchDialogComponent } from 'components/advanced-search-dialog/advanced-search-dialog.component';
import { AddCashRegisterItemDialogComponent } from '../app/modules/cash-register/cash-register/add-cash-register-item-dialog/add-cash-register-item-dialog.component';
import { ModalService } from 'core/modal.service';
import { Session } from 'core/session.service';
import * as moment from 'moment';
import { ShortcutInput } from 'ng-keyboard-shortcuts';

import * as numeral from 'numeral';
import 'numeral/locales/cs';
import { LocalStorageService } from 'core/local-storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
    public shortcuts: ShortcutInput[] = [];
    title = 'app';
    public loading = true;

    constructor(
        private modal: ModalService,
        private router: Router,
        private session: Session,
        private localStorage: LocalStorageService,
        private oidcSecurityService: OidcSecurityService
    ) {
        moment.locale('cs');
        numeral.locale('cs');
    }

    ngOnInit() {
        this.oidcSecurityService.checkAuth().subscribe((a) => {
         /*    if (a) {
                if (
                    this.localStorage.local.getItem('fssb-login-return-url') &&
                    this.localStorage.local.getItem('fssb-login-return-url') !== window.location.href
                ) {
                   window.location.href = this.localStorage.local.getItem('fssb-login-return-url'); // nastaveni defaultni url ??
                }
            } */

            if (!this.session.store) {
                Promise.all([this.session.loadUser()]).then(
                    () => {
                        this.loading = false;
                    },
                    () => {
                        this.loading = false;
                    }
                );
            } else {
                this.loading = false;
            }
        });

        this.shortcuts.push({
            key: 'f1',
            preventDefault: true,
            command: (e) => {
                alert('hledat klienta');
            },
        });

        this.shortcuts.push({
            key: 'f2',
            preventDefault: true,
            command: (e) => {
                this.modal.open(AddCashRegisterItemDialogComponent).subscribe(
                    () => {
                        this.router.navigate(['/cash-register']);
                    },
                    () => {}
                );
            },
        });

        /* this.shortcuts.push({
            key: 'f2',
            preventDefault: true,
            command: (e) => {
                this.router.navigate(['/clients/detail/-1']);
            },
        }); */

        /*   this.shortcuts.push({
            key: 'f3',
            preventDefault: true,
            command: (e) => {
                this.modal.open(AdvancedSearchDialogComponent).subscribe(
                    () => {},
                    () => {}
                );
            },
        }); */

        this.shortcuts.push({
            key: 'f10',
            preventDefault: true,
            command: (e) => {
                this.router.navigate(['/contracts']);
            },
        });
    }
}

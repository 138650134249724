import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ClientsDataService } from 'core/dataservices/clients-data.service';
import { SubjektyDto } from 'models/custom';

@Component({
    selector: 'app-subject-select-dialog',
    templateUrl: './subject-select-dialog.component.html',
    styleUrls: ['./subject-select-dialog.component.scss'],
})
export class SelectSubjectDialogComponent implements OnInit {
    public subjects: SubjektyDto[] = [];

    private top = 20;
    private skip = 0;

    constructor(
        public dialog: MatDialogRef<SelectSubjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public context: any,
        private data: ClientsDataService
    ) {}

    ngOnInit(): void {
        this.loadData();
    }

    loadData() {
        this.data.getClientsList({ top: this.top, skip: this.skip }).subscribe((a) => {
            this.subjects.push(...a.values);
        });
    }

    close() {
        this.dialog.close();
    }

    selectSubject(s: SubjektyDto) {
        this.dialog.close(s);
    }

    loadMore() {
        this.skip += 20;
        this.loadData();
    }

    submit() {}
}

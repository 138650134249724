import { Component, forwardRef, Host, Input, OnInit, Optional, SkipSelf } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidationMessages } from '../validation-messages/validation-messages';
import { FormInputBase } from '../form-input-base';

const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputComponent),
    multi: true,
};

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ValidationMessages, useClass: ValidationMessages }],
})
export class InputComponent extends FormInputBase implements OnInit {
    public value: any;
    @Input() placeholder: string;

    writeValue(obj: any): void {
        this.value = obj === undefined ? null : obj;
    }
    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        validationMessages: ValidationMessages
    ) {
        super(controlContainer, validationMessages);
    }

    ngOnInit(): void {}

    onInput(value: any) {
        this.raiseOnChange(value);
    }

    onBlur() {
        this.raiseOnTouched();
    }
}

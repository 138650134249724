import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ITagDto, PripadyDto, PripadyPolozkyDto } from 'models';
import { map } from 'rxjs/operators';
import { IVypocitejUroky } from 'models/custom';
import { ICashRegistry } from 'models/cashregistry';
import { ModalService } from 'core/modal.service';
import { PinDialogComponent } from './pin-dialog/pin-dialog.component';
import { PripadyPolozkyDtoExt } from 'modules/contracts/contract-detail/contract-detail.component';

@Injectable({
    providedIn: 'root',
})
export class ContractDataService {
    private duplicateLock: boolean = true;
    private duplicateContract: any;

    constructor(private http: HttpClient, private modal: ModalService) {}

    getContractList(filter = {}): Observable<any> {
        const opts = { params: new HttpParams({ fromObject: filter }) };
        return this.http.get('/api/pripady', opts);
    }

    getContractDetail(idPripad: number): Observable<PripadyDto> {
        return this.http.get('/api/pripady/detail?idPripad=' + idPripad);
    }

    getContractItemsBySn(sn: string): Observable<PripadyPolozkyDto> {
        return this.http.get<PripadyPolozkyDto>('/api/pripadyPolozky/search-sn?sn=' + sn.toString());
    }

    /*   postContractDetail(data: PripadyDto): Observable<number> {
        return this.http.post('/api/pripady/', data).pipe(
            map((r: any) => {
                return r.idPripad;
            })
        );
    }  */

    async postContractDetail(data, wrongPIN: boolean = false) {
        let response = 0;
        let id = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('/api/pripady', data, { headers })
                    .pipe(
                        map((x: any) => {
                            id = x.idPripad;
                        })
                    )
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.postContractDetail(data, true);
        else return id;
    }
    /*
    patchContractDetail(data: PripadyDto) {
        return this.http.patch('/api/pripady', data);
    } */

    async patchContractDetail(data: PripadyDto, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('/api/pripady', data, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.patchContractDetail(data, true);
        else return response;
    }

    getDetailCds() {
        return this.http.get('/api/pripadyPolozky/cds');
    }

    getNazvySearch(term: string, typ: number, idZnacka?: number): Observable<string[]> {
        return this.http
            .get(`/api/pripadypolozky/nazvy?idPolozkaTyp=${typ}&nazevTxt=${term}` + (idZnacka ? `&idZnacka=${idZnacka}` : ''))
            .pipe(
                map((a: any) => {
                    if (a) {
                        return a.map((b) => b.nazev);
                    } else {
                        return [];
                    }
                })
            );
    }

    saveContractItem(data: PripadyPolozkyDto[]): Observable<any> {
        return this.http.post('/api/pripadypolozky', data);
    }

    getContractItems(idPripad: number): Observable<any[]> {
        return this.http.get<any>('/api/pripadypolozky?idPripad=' + idPripad);
    }

    getIntrestCalculation(data = {}): Observable<IVypocitejUroky> {
        const opts = { params: new HttpParams({ fromObject: data }) };
        return this.http.get<IVypocitejUroky>('/api/pripady/vypocitej-uroky', opts);
    }

    getMultipleIntrestCalculation(data = {}): Observable<IVypocitejUroky> {
        const opts = { params: new HttpParams({ fromObject: data }) };
        return this.http.get<IVypocitejUroky>('/api/pripady/hromadne/vypocitej-uroky', opts);
    }

    getTags(data = {}): Observable<ITagDto[]> {
        return this.http.get<ITagDto[]>('/api/pripady/tagy-seznam');
    }

    async postTags(idPripad: number, tags: ITagDto[], wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('api/pripady/tagy-merge', { idPripad: idPripad, tagy: tags }, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.postTags(idPripad, tags, true);
        else return response;
    }
    /*
    postPayOff(idPripad: number, urok: number, platba: number) {
        return this.http.post('/api/pripady/vyplatit', { idPripad: idPripad, urok: urok, platba: platba });
    } */

    async postPayOff(idPripad: number, urok: number, platba: number, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('/api/pripady/vyplatit', { idPripad: idPripad, urok: urok, platba: platba }, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.postPayOff(idPripad, urok, platba, true);
        else return response;
    }

    async postMultiplePayOff(idPripadStr: string, Urok: number, Platba: number, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('/api/pripady/hromadne/vyplatit', { idPripadStr: idPripadStr, Urok: Urok, Platba: Platba }, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.postMultiplePayOff(idPripadStr, Urok, Platba, true);
        else return response;
    }

    /* postAditionalLoan(idPripad: number, castka: number, poznamka: string)) {
        return this.http.post('/api/pripady/dopujcit', { idPripad: idPripad, castka: castka, poznamka: poznamka });
    } */

    async postStolenSN(SN: string, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('/api/pripadyPolozky/kradene', { vyrobniCislo: SN }, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.postStolenSN(SN, true);
        else return response;
    }

    async postAditionalLoan(idPripad: number, castka: number, poznamka: string, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('/api/pripady/dopujcit', { idPripad: idPripad, castka: castka, poznamka: poznamka }, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.postAditionalLoan(idPripad, castka, poznamka, true);
        else return response;
    }

    async postService(idPripad: number, castka: number, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('/api/pripady/servis', { idPripad: idPripad, castka: castka }, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.postService(idPripad, castka, true);
        else return response;
    }

    /*   duplicateCase(idPripad: number): Observable<{ idPripad: number }> {
        return this.http.post<{ idPripad: number }>('/api/pripady/duplikovat', { idPripad: idPripad });
    } */

    /* async duplicate(case:any, wrongPIN: boolean = false){
        let response = 405;
        await this.modal.open(PinDialogComponent,wrongPIN).toPromise().then(
            async (val) => {
                response = 200;
                this.duplicateLock=false;
            });
        if(response===405)
        return this.duplicateCase(idPripad,true);
        else
        return id;
    } */

    setDuplicate(caseObj: any) {
        this.duplicateContract = [];
        this.duplicateContract = caseObj;
        this.duplicateLock = false;
    }

    getDuplicate() {
        if (this.duplicateLock === false) {
            this.duplicateLock = true;
            return this.duplicateContract;
        } else return 405;
    }

    async duplicateCase(idPripad: number, wrongPIN: boolean = false) {
        let response = 0;
        let id = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post<{ idPripad: number }>('/api/pripady/duplikovat', { idPripad: idPripad }, { headers })
                    .toPromise()
                    .then((val) => {
                        id = val.idPripad;
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.duplicateCase(idPripad, true);
        else return id;
    }

    /*  vystavitCase(idPripad: number): Observable<{ idPripad: number }> {
        return this.http.post<{ idPripad: number }>('/api/pripady/vystavit', { idPripad: idPripad });
    } */

    async vystavitCase(idPripad: number, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post<{ idPripad: number }>('/api/pripady/vystavit', { idPripad: idPripad }, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.vystavitCase(idPripad, true);
        else return response;
    }

    async vystavitZaCase(idPripad: number, castka: number, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post<{ idPripad: number }>('/api/pripady/vystavit-za', { idPripad: idPripad, vystavenoZa: castka }, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.vystavitZaCase(idPripad, castka, true);
        else return response;
    }

    async vratitCase(idPripad: number, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post<{ idPripad: number }>('/api/pripady/vratit-do-zastavy', { idPripad: idPripad }, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.vratitCase(idPripad, true);
        else return response;
    }

    /*
    postProdlouzitSplatnost(data: { idPripad: number; splatnost: string; urok?: number; platba?: number }): Observable<any> {
        return this.http.post('/api/pripady/prodlouzit-splatnost', data);
    } */

    async postProdlouzitSplatnost(
        data: { idPripad: number; splatnost: string; urok?: number; platba?: number },
        wrongPIN: boolean = false
    ) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('/api/pripady/prodlouzit-splatnost', data, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.postProdlouzitSplatnost(data, true);
        else return response;
    }

    async postMultipleProdlouzitSplatnost(
        data: { idPripadStr: string; Splatnost: string; urok?: number; platba?: number },
        wrongPIN: boolean = false
    ) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('/api/pripady/hromadne/prodlouzit-splatnost', data, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.postMultipleProdlouzitSplatnost(data, true);
        else return response;
    }

    /* postProdat(data: { idPripad: number; castka: number; prodanoJinemuMajiteli: boolean }): Observable<any> {
        return this.http.post('/api/pripady/prodat', data);
    } */

    async postProdat(data: { idPripad: number; castka: number; prodanoJinemuMajiteli: boolean; datum: string }, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post('/api/pripady/prodat', data, { headers })
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.postProdat(data, true);
        else return response;
    }

    getCashRegistry(idPripad: number): Observable<{ count: number; values: ICashRegistry[] }> {
        return this.http.get<{ count: number; values: ICashRegistry[] }>('/api/pokladnidenik?idPripad=' + idPripad);
    }

    modifyContractName(name: string): string {
        if (name === null || name === undefined) return '';
        if (name.length < 2) return name;
        return name.substring(0, 2) + '/' + name.substring(2);
    }

    async setSellItem(item: PripadyPolozkyDtoExt, datum: string, castka: number, jinyMajitel: boolean, wrongPIN: boolean = false) {
        let response = 0;
        await this.modal
            .open(PinDialogComponent, wrongPIN)
            .toPromise()
            .then(async (val) => {
                let headers = new HttpHeaders({ pin: val });
                await this.http
                    .post(
                        `/api/pripadypolozky/prodat`,
                        {
                            idPripad: item.idPripad,
                            idPolozkaSeq: item.idPolozkaSeq,
                            castka: castka,
                            datum: datum,
                            prodanoJinemuMajiteli: jinyMajitel,
                        },
                        { headers }
                    )
                    .toPromise()
                    .then((val) => {
                        response = 200;
                    })
                    .catch((err) => {
                        response = err.status;
                    });
            });
        if (response === 405) return this.setSellItem(item, datum, castka, jinyMajitel, true);
        else return response;
    }
}

import { TemplateRef } from '@angular/core';

export type messageFactory = (error: any) => string | TemplateRef<any>;

export class ValidationMessages {
    public messages = new Map<string, messageFactory>();

    constructor() {
        this.messages.set('required', () => 'Toto pole je povinné');
    }

    public getTemplate(errorCode: string, error: any) {
        const value = this.getValue(errorCode, error);
        if (!value || typeof value === 'string' || value instanceof String) {
            return null;
        }
        return value;
    }

    public getText(errorCode: string, error: any) {
        const value = this.getValue(errorCode, error);
        if (value && (typeof value === 'string' || value instanceof String)) {
            return value;
        }
        return null;
    }

    private getValue(errorCode: string, error: any) {
        const msg = this.messages.get(errorCode);
        if (!msg) {
            return null;
        }
        return msg(error);
    }
}

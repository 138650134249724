import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface AppButtonClickEvent {
    readonly originalEvent: MouseEvent;
    stopLoading();
}

export class AppButtonEventImpl {
    readonly originalEvent: MouseEvent;
    #nextResolve: (value?: void | PromiseLike<void>) => void;
    public next: Promise<void>;

    constructor(event: MouseEvent) {
        this.originalEvent = event;
        this.next = new Promise((resolve) => {
            this.#nextResolve = resolve;
        });
    }

    public stopLoading() {
        this.#nextResolve();
    }
}

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
    public isLoading = false;

    @Output() click = new EventEmitter<AppButtonClickEvent>();
    @Input() indicateLoading = false;

    constructor() {}

    ngOnInit(): void {}

    public onClick(event: MouseEvent) {
        event.stopPropagation();
        if (this.isLoading) {
            return;
        }
        const buttonEvent = new AppButtonEventImpl(event);
        buttonEvent.next.then(() => {
            this.isLoading = false;
        });
        if (!this.indicateLoading) {
            this.isLoading = false;
            buttonEvent.stopLoading();
        } else {
            this.isLoading = true;
        }

        this.click.emit(buttonEvent);
    }
}

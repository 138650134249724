<div class="rModal theme-yellow">
    <div class="rModal_header">
        <i class="icon-search"></i>
        <h3 class="">Rozšířené hledání</h3>
    </div>
    <div class="rModal_content">
        <label class="dialogInput row">
            <label class="col-4 pr-0">Číslo smlouvy</label>
            <div class="col-8">
                <input type="text" (keydown.enter)="submit()" [(ngModel)]="data.cisloSmlouvy" />
            </div>
        </label>
        <label class="dialogInput row">
            <label class="col-4 pr-0">Výrobní číslo</label>
            <div class="col-8">
                <input type="text" (keydown.enter)="submit()" [(ngModel)]="data.vyrobniCislo" />
            </div>
        </label>
        <label class="dialogInput row">
            <label class="col-4 pr-0">Název položky</label>
            <div class="col-8">
                <input type="text" (keydown.enter)="submit()" [(ngModel)]="data.nazevPolozky" />
            </div>
        </label>
    </div>
    <div class="rModal_footer">
        <button class="rBtn wide cancel" (click)="close()">Zrušit</button>
        <button class="rModal_submitBtn" (click)="submit()">Vyhledat</button>
    </div>
</div>

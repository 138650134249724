import { HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubjektyCDS, SubjektySouvisejici } from 'models/custom';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalService } from 'core/modal.service';
import { PinDialogComponent } from './pin-dialog/pin-dialog.component';

@Injectable({
    providedIn: 'root',
})
export class ClientsDataService {
    constructor(private http: HttpClient, private modal: ModalService) {}

    getClientCDS(): Observable<SubjektyCDS> {
        return this.http.get<SubjektyCDS>('/api/subjekty/cds');
    }

    getClientsList(filter = {}): Observable<any> {
        const opts = { params: new HttpParams({ fromObject: filter }) };
        return this.http.get('/api/subjekty', opts);
    }

    getClientDetail(idSubjekt) {
        return this.http.get('/api/subjekty/detail?idSubjekt=' + idSubjekt);
    }

    getClientRelatedSubjects(idSubjekt: number): Observable<SubjektySouvisejici[]> {
        return this.http.get<{ values: SubjektySouvisejici[] }>('/api/subjekty/souvisejici?idSubjekt=' + idSubjekt).pipe(
            map((a) => {
                {
                    return a.values;
                }
            })
        );
    }

  /*   postClientDetail(data) {
        return this.http.post('/api/subjekty', data).pipe(
            map((r: any) => {
                return r.idSubjekt;
            })
        );
    } */

    async postClientDetail(data, wrongPIN: boolean = false){
        let response = 0;
        let id = 0;
        await this.modal.open(PinDialogComponent,wrongPIN).toPromise().then(
            async (val) => {
                let headers = new HttpHeaders({ 'pin': val });
                await this.http.post('/api/subjekty', data, { headers }).pipe(map((x : any) => {id=x.idSubjekt})).toPromise()
                .then(
                    val => {
                                response=200;
                            })
                .catch(
                    err => {
                                alert(err.error.Message);
                                response = err.error.StatusCode;
                            });
            });
        if(response===405)
        return this.postClientDetail(data,true);
        else
        return id;
    }

    /* updateClientDetail(data) {
        return this.http.patch('/api/subjekty', data).pipe(
            map((r: any) => {
                return r.idSubjekt;
            })
        );
    } */

    async updateClientDetail(data, wrongPIN: boolean = false){
        let response = 0;
        let id = 0;
        await this.modal.open(PinDialogComponent,wrongPIN).toPromise().then(
            async (val) => {
                let headers = new HttpHeaders({ 'pin': val });
                await this.http.patch('/api/subjekty', data, { headers }).pipe(map((x : any) => {id=x.idSubjekt})).toPromise()
                .then(
                    val => {
                                response=200;
                            })
                .catch(
                    err => {
                                response = err.status;
                            });
            });
        if(response===405)
        return this.postClientDetail(data,true);
        else
        return id;
    }


    insertSouvisejiciOsoba(data: { idSubjekt: number; idSubjektPodrizeny: number; druh: string, poznamka: string }) {
        return this.http.post('/api/subjekty/souvisejici', data);
    }

    deleteSouvisejiciOsoba(data: { idSubjekt: number; idSubjektPodrizeny: number }) {
        return this.http.delete('/api/subjekty/souvisejici?idSubjekt=' + data.idSubjekt + '&idSubjektPodrizeny=' + data.idSubjektPodrizeny);
    }

    getOfficeSuggestion(searchTxt: string){
        return this.http.get('api/subjekty/doklady?searchTxt='+searchTxt);
    }

    getCheckRC(rc: string){
        return this.http.get('api/subjekty/existuje-rc?rc='+rc);
    }

    suggestAddress(input: string){
            this.http.get("https://photon.komoot.io/api/?q="+input)
             .subscribe((data) => {
                 var features = data["features"];
                 var parsed = [];
                 for(var i = 0; i < features.length;i++){
                   var f = features[i].properties;
                   if(f.type=="city"){
                        var label = "";
                        if(f.name) label+= f.name + ", ";
                        if(f.street && f.district && f.housenumber && f.postcode){
                            label+= f.street + " ";
                            label+= f.housenumber + ", ";
                            label+= f.postcode + " ";
                            label+= f.district + " ";
                        }else if(f.housenumber && f.district && f.postcode){
                            label+= f.district + " ";
                            label+= f.housenumber + ", ";
                            label+= f.postcode + " ";
                        }else if(f.district && f.postcode){
                            label+= f.district + " ";
                            label+= f.postcode + " ";
                        }else if(f.city && f.housenumber){
                            label+= f.city + " " + f.housenumber + ", " + f.postcode;
                        }else{
                            if(f.name) label+= f.name;
                        }
                        parsed.push({
                            text:label,
                            gps:features[i].geometry.coordinates
                        });
                    }
                    else parsed=[""];
                 }
                 return parsed;
             });
    }
}

import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'moment',
    pure: false,
})
export class MomentPipe implements PipeTransform {
    private _value: string;

    constructor(private changeDetector: ChangeDetectorRef) {}

    private updateValue(value: any, format?: string): string {
        if (!value) {
            this._value = value;
            return;
        }
        const m = moment(value);
        if (m.isValid()) {
            format = format || 'll';
            if (format.indexOf(' ') !== -1) {
                const formatParts = format.split(' ');
                const res = [];
                formatParts.forEach((f) => {
                    res.push(m.format(f));
                });
                this._value = res.join(' ');
                return;
            } else {
                this._value = format ? m.format(format) : m.format('ll');
                return;
            }
        } else {
            this._value = '';
            return;
        }
    }

    transform(value: any, format?: string): any {
        this.updateValue(value, format);
        return this._value;
    }
}

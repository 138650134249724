import { Injectable } from '@angular/core';

class Cache {
    private _storage: Storage;
    private _isSupported = false;
    private _prefix = 'evx';

    constructor(storage: Storage) {
        this._storage = storage;
        this._isSupported = storage != null && storage !== undefined;
    }

    public setItem(key: string, item: any) {
        if (!this._isSupported) {
            return;
        }
        if (item === undefined) {
            item = null;
        }
        const serializedItem = JSON.stringify(item);
        try {
            this._storage.setItem(this.deriveKey(key), serializedItem);
        } catch (error) {
            console.error(error);
        }
    }

    public getItem<T>(key: string): T {
        if (!this._isSupported) {
            return null;
        }
        const item = this._storage.getItem(this.deriveKey(key));
        if (item) {
            const deserializedItem = JSON.parse(item);
            return deserializedItem as T;
        } else {
            return null;
        }
    }

    public removeItem(key: string) {
        if (!this._isSupported) {
            return;
        }
        this._storage.removeItem(this.deriveKey(key));
    }

    private deriveKey(key: string) {
        return `${this._prefix}.${key}`;
    }
}

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    local: Cache;
    session: Cache;

    constructor() {
        this.local = new Cache(window.localStorage);
        this.session = new Cache(window.sessionStorage);
    }
}

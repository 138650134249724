import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Pipe({
    name: 'timeago',
    pure: false,
})
export class TimeagoPipe implements PipeTransform {
    constructor() {}

    transform(value: any): any {
        if (!value) {
            return value;
        }
        const m = moment(value);
        const now = moment();
        return now.to(m);
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'yearsToNow',
    pure: false,
})
export class YearsToNowPipe implements PipeTransform {
    constructor() { }

    transform(value: any): number {
        if (!value) {
            return 0;
        }
        const now = moment();
        const bd = moment(value, 'DD.MM.YYYY');
        const duration = moment.duration(now.diff(bd));
        if(duration.years()){
            return duration.years();
        }else{
            const bd = moment(value, 'YYYY.MM.DD');
            const duration = moment.duration(now.diff(bd));
            return duration.years();
        }
    }
}

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CashregisterService } from 'core/dataservices/cashregister.service';
import { ICashRegistryCDS, ICashRegistryInsertSP } from 'models/cashregistry';
import * as moment from 'moment';

@Component({
    selector: 'app-add-cash-register-item-dialog',
    templateUrl: './add-cash-register-item-dialog.component.html',
    styleUrls: ['./add-cash-register-item-dialog.component.scss'],
})
export class AddCashRegisterItemDialogComponent implements OnInit {
    public cds: ICashRegistryCDS;
    public dataObj: ICashRegistryInsertSP = { pobocka: 'ZAKAZKOVYVYVOJ' };
    public requestBlocker : boolean = false;

    constructor(
        public dialog: MatDialogRef<AddCashRegisterItemDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public context: any,
        public data: CashregisterService
    ) {}

    ngOnInit(): void {
        this.dialog.updateSize('500px');
        this.getCDS();
    }

    getCDS() {
        this.data.getCDS('P').subscribe((a) => {
            this.cds = a;

            let tmp = this.cds.cDenikOperace[0];
            this.cds.cDenikOperace[0]=this.cds.cDenikOperace[1];
            this.cds.cDenikOperace[1] = tmp;

            this.dataObj.idOperace = this.cds.cDenikOperace[0].idOperace;
        });
    }

    submit() {

        if (!this.dataObj.castka) {
            alert('Musíte zadat částku');
            return;
        }
        if(this.dataObj.castka<0)
        {
            alert("Částka musí být kladná");
            return;
        }
        if (!this.dataObj.popis || (this.dataObj.popis && this.dataObj.popis.length < 5)) {
            alert('Musíte zadat popis, minimálně 5 znaků');
            return;
        }
        this.requestBlocker=true;
        this.data.postNewRecord(this.dataObj).then((response) => {
            if(response===200)
            this.dialog.close();
            else{
                alert("Nastala chyba!")
            }
            this.requestBlocker=false;
        });
    }

    close() {
        this.dialog.close();
    }
}

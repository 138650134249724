import { Component, OnInit } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Session } from 'core/session.service';
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { filter, last, map } from "rxjs/operators";
import { NavigationService } from 'core/navigation.service';
import { ShortcutInput } from 'ng-keyboard-shortcuts';

export interface IPobocka {
nazev: string;
pobocka: string;
}
@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    public shortcuts: ShortcutInput[] = [];
    public openMenu = false;
    public showNav = true;
    public lastSize = 0;
    public pobocky  : IPobocka [];

    constructor(
        private oicd: OidcSecurityService,
        public session: Session,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private navigation: NavigationService
        ) {}

    ngOnInit(): void {
      this.session.getPobocky().then((a: any)=>{
        this.pobocky = a;
      });
      this.shortcuts.push({
        key: 'right',
        preventDefault: true,
        command: (e) => {
          this.navigation.setMenuItem(this.navigation.getMenuItem()+1);
          if(this.navigation.getMenuItem()>4){
            this.navigation.setMenuItem(0);
          }
          this.moveToLocation();
        },
      });

      this.shortcuts.push({
        key: 'left',
        preventDefault: true,
        command: (e) => {
          this.navigation.setMenuItem(this.navigation.getMenuItem()-1);
          if(this.navigation.getMenuItem()<0){
            this.navigation.setMenuItem(4);
          }
          this.moveToLocation();
        },
      });

        this.router.events
          .pipe(filter((x: any) => x instanceof NavigationEnd))
          .subscribe(() => { 
            if(this.router.url.includes("unauth")){
              this.showNav = false;
            }
            else{
              this.showNav = true;
            }
          });
      }
    updateLast(){
      this.lastSize=this.navigation.getHistory().length
    }
    onContractsClick(){
     this.router.navigateByUrl('/contracts').then((a)=>{
      if(a===null)
      window.location.reload();
     });
    }
    onClientsClick(){
      this.router.navigateByUrl('/clients').then((a)=>{
       if(a===null)
       window.location.reload();
      });
     }
    logout() {
        this.oicd.logoff();
    }

    moveToLocation(){
      if(this.navigation.getMenuItem()===0){
        this.router.navigateByUrl('/')
      }
      if(this.navigation.getMenuItem()===1){
        this.router.navigateByUrl('/contracts')
      }
      if(this.navigation.getMenuItem()===2){
        this.router.navigateByUrl('/clients')
      }
      if(this.navigation.getMenuItem()===3){
        this.router.navigateByUrl('/cash-register')
      }
      if(this.navigation.getMenuItem()===4){
        this.router.navigateByUrl('/overview')
      }
    }
    zmenPobocku(pobocka: string){
      this.session.changePobocka(pobocka);
    }
}

<div *ngIf="control && !control.valid && control.touched">
    <div *ngFor="let k of control.errors | keyvalue" class="text-danger">
        <div *ngIf="!validationMessages.getTemplate(k.key, control.getError(k.key)); else template">
            {{ validationMessages.getText(k.key, control.getError(k.key)) }}
        </div>
        <ng-container
            #template
            *ngTemplateOutlet="
                validationMessages.getTemplate(k.key, control.getError(k.key));
                context: { $implicit: control.getError(k.key) }
            "
        >
        </ng-container>
    </div>
</div>

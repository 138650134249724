import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { InputComponent } from './forms/input/input.component';
import { NumberInputComponent } from './forms/number-input/number-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationMessagesComponent } from './forms/validation-messages/validation-messages.component';
import { ValidationMessageDirective } from './forms/validation-messages/validation-message.directive';
import { NavbarComponent } from './navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { UploadDocumentDialogComponent } from './upload-document-dialog/upload-document-dialog.component';
import { AdvancedSearchDialogComponent } from './advanced-search-dialog/advanced-search-dialog.component';
import { SelectSubjectDialogComponent } from './subject-select-dialog/subject-select-dialog.component';
import { ClientsDataService } from 'core/dataservices/clients-data.service';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';

const exports = [
    ButtonComponent,
    InputComponent,
    ValidationMessagesComponent,
    ValidationMessageDirective,
    NavbarComponent,
    AdvancedSearchDialogComponent,
    SelectSubjectDialogComponent,
    NumberInputComponent,
];

@NgModule({
    providers: [ClientsDataService],
    declarations: [...exports, UploadDocumentDialogComponent],
    exports: [FormsModule, ReactiveFormsModule, ...exports],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, KeyboardShortcutsModule.forRoot()],
})
export class ComponentsModule {}

import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import * as numeral from 'numeral';
import { Subscription } from 'rxjs';

@Pipe({
    name: 'numeral',
    pure: false,
})
export class NumeralPipe implements PipeTransform, OnDestroy {
    public onTranslationChange = new Subscription();
    public onLangChange = new Subscription();
    public onDefaultLangChange = new Subscription();

    private _value: string;

    constructor() {}

    private updateValue(value: any, format?: string): string {
        if (!value) {
            this._value = value;
            return;
        }
        const m = numeral(value);
        this._value = format ? m.format(format) : m.format('0,0.00');
        return;
    }

    transform(value: any, format?: string): any {
        this.updateValue(value, format);
        return this._value;
    }

    ngOnDestroy(): void {}
}

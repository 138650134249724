import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContractDataService } from 'core/dataservices/contract-data.service';

@Component({
    selector: 'app-advanced-search-dialog',
    templateUrl: './advanced-search-dialog.component.html',
    styleUrls: ['./advanced-search-dialog.component.scss'],
})
export class AdvancedSearchDialogComponent implements OnInit {
    public data: { cisloSmlouvy?: string; vyrobniCislo?: string; nazevPolozky?: string } = {};
    constructor(
        public dialog: MatDialogRef<AdvancedSearchDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public context: any,
        private dataService: ContractDataService
    ) {}

    ngOnInit(): void {
        this.dialog.updateSize('500px');
    }

    close() {
        this.dialog.close();
    }

    submit() {
        alert(this.data);
    }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Session } from './session.service';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private oidcSecurityService: OidcSecurityService, // Fixed typo in parameter name
        private session: Session,
        private router: Router,
        private localStorage: LocalStorageService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return this.oidcSecurityService.getAccessToken().pipe(
            switchMap((token: string) => {
                let modifiedReq = request;

                if (request.url.indexOf('metadata') === -1 || request.url.indexOf('openid-configuration') === -1) {
                    modifiedReq = request.clone({
                        headers: request.headers.append('Authorization', `Bearer ${token}`),
                    });

                    if (this.session.store) {
                        modifiedReq = modifiedReq.clone({
                            headers: modifiedReq.headers.append('Pobocka', this.session.store.pobocka),
                        });
                    }
                }

                return next.handle(modifiedReq).pipe(
                    catchError((err) => {
                        if (err instanceof HttpErrorResponse) {
                            switch (err.status) {
                                case 400:
                                    break;
                                case 401:
                                  /*   this.localStorage.local.setItem('fssb-login-return-url', window.location.href); */
                                    setTimeout(() => {
                                        this.oidcSecurityService.authorize();
                                      }, 2000);
                                    break;
                                case 403:
                                     this.router.navigate(['/dashboard/unauthorized']); 
                                    break;
                                case 500:
                                    break;
                            }
                        }
                        return throwError(err);
                    })
                );
            })
        );
    }
}

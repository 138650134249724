<ng-keyboard-shortcuts [shortcuts]="shortcuts"></ng-keyboard-shortcuts>
<div *ngIf="showNav" class="l-navbar">
    <div class="navbar">
       <div class="navbar_logo"><a [routerLink]="['/']"><img src="assets/renato_medium.png" width="152px" height="65px"></a></div>
        <div class="navbar_menu" [class.isOpen]="openMenu">
            <div class="menu_hamburger" (click)="openMenu = !openMenu">
                <i class="material-icons menu">menu</i>
                <i class="material-icons closing">clear</i>
            </div>
            <ul class="menu_list">
                <li class="menu_item" [ngClass]="{'selectedItem': selectedMenuItem === 0}">
                    <a [routerLink]="['/']" (click)="selectedMenuItem=0">Dashboard</a>
                </li>
                <li class="menu_item" [ngClass]="{'selectedItem': selectedMenuItem === 1}">
                    <a [routerLink]="['/contracts']" (click)="onContractsClick();selectedMenuItem=1" routerLinkActive="isActive">Smlouvy</a>
                </li>
                <li class="menu_item" [ngClass]="{'selectedItem': selectedMenuItem === 2}">
                    <a [routerLink]="['/clients']" (click)="onClientsClick();selectedMenuItem=2" routerLinkActive="isActive">Klienti</a>
                </li>
                <li class="menu_item" [ngClass]="{'selectedItem': selectedMenuItem === 3}">
                    <a [routerLink]="['/cash-register']" (click)="selectedMenuItem=3" routerLinkActive="isActive">Pokladna</a>
                </li>
                <li class="menu_item" *ngIf="session.store.isAdmin" [ngClass]="{'selectedItem': selectedMenuItem === 4}">
                    <a [routerLink]="['/overview']" (click)="selectedMenuItem=4" routerLinkActive="isActive">Přehledy</a>
                </li>
                <!-- <li class="menu_item">
                    <a [routerLink]="['/settings']" routerLinkActive="isActive">Nastavení</a>
                </li> -->
            </ul>
        </div>
        <div class="navbar_user">
            <div class="dropdown">
               <!--  {{ session.store ? session.store?.pobocka : '' }} -->
                <button
                    class="dropdown-btn navbar_user_profile"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span>{{ session.store ? session.store?.pobocka : '' }}</span>
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <button class="dropdown-item" [class.isAdmin] = "session.store.isAdmin" (click)="logout()">
                        <i class="material-icons dropdown-icon"></i>
                        <span class="dropdown-text">Odhlásit se</span>
                    </button>
                    <ng-container *ngIf="session.store.isAdmin">
                        <button *ngFor="let p of pobocky" class="dropdown-item" (click)="zmenPobocku(p.pobocka)">
                            <i class="material-icons dropdown-icon"></i>
                            <span class="dropdown-text">{{p.pobocka}}</span>
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

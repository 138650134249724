import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-upload-document-dialog',
    templateUrl: './upload-document-dialog.component.html',
    styleUrls: ['./upload-document-dialog.component.scss']
})
export class UploadDocumentDialogComponent implements OnInit {

    constructor(public dialog: MatDialogRef<UploadDocumentDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public context: any) { }

    ngOnInit(): void {
        this.dialog.updateSize('500px');
    }

}

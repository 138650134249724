import { Component, Inject, OnInit,  ViewChild, AfterViewInit, OnDestroy  } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-pin-dialog',
  templateUrl: './pin-dialog.component.html',
  styleUrls: ['./pin-dialog.component.scss']
})
export class PinDialogComponent implements OnInit {
  @ViewChild('num1') input1:any;
  @ViewChild('num2') input2:any;
  @ViewChild('num3') input3:any;
  @ViewChild('num4') input4:any;

  pinTest : string= "";

  oneFilled : boolean = false;
  twoFilled : boolean = false;
  threeFilled : boolean = false;
  fourFilled : boolean = false;

  constructor(
    public dialog: MatDialogRef<PinDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public context: {wrongPIN: boolean;},

) {}

  ngOnInit(): void {
    this.dialog.updateSize('500px');
  }
  ngOnDestroy(): void{
    if(this.pinTest.length<4)
    alert("Nebyl zadán PIN, akce nebyla provedena.")
  }

  onDigitInput(event){
    let PIN : string = this.input1.nativeElement.value + this.input2.nativeElement.value + this.input3.nativeElement.value + this.input4.nativeElement.value;
    this.pinTest = PIN;

    if(PIN.length==4){
      this.dialog.close(PIN);
    }
    let element;
     if (event.code === 'Backspace'){
        if(event.target.name==="one"){
          this.oneFilled = false;
          this.input1.nativeElement.value = "";
        }
        if(event.target.name==="two"){
          this.twoFilled = false;
          this.input2.nativeElement.value = "";
        }
        if(event.target.name==="three"){
          this.threeFilled = false;
          this.input3.nativeElement.value = "";
        }
        if(event.target.name==="four"){
          this.fourFilled = false;
          this.input4.nativeElement.value=""
        }
        if(event.srcElement.previousElementSibling!==null){
          element = event.srcElement.previousElementSibling;
          element.focus();
          if(element.name==="one"){
            this.oneFilled = false;
            this.input1.nativeElement.value = "";
          }
          if(element.name==="two"){
            this.twoFilled = false;
            this.input2.nativeElement.value = "";
          }
          if(element.name==="three"){
            this.threeFilled = false;
            this.input3.nativeElement.value = "";
          }
          if(element.name==="four"){
            this.fourFilled = false;
            this.input4.nativeElement.value= "";
          }
        }
      }

     else if (event.code !== 'Backspace' && event.code !== 'Minus' && event.code !== 'Plus' && event.code !== 'Equal' && event.code !== 'Period' && event.code !== 'Comma' && event.code !== 'Tab'){
        if(event.target.name==="one"&&this.input1.nativeElement.value!==""&&this.oneFilled===false&&this.twoFilled==false&&this.threeFilled==false){
         this.oneFilled = true;
         element = event.srcElement.nextElementSibling;   
        }
        if(event.target.name==="two"&&this.input2.nativeElement.value!==""&&this.oneFilled===true&&this.twoFilled==false&&this.threeFilled==false){
          this.twoFilled = true;
          element = event.srcElement.nextElementSibling;
         }
         if(event.target.name==="three"&&this.input3.nativeElement.value!==""&&this.oneFilled===true&&this.twoFilled==true&&this.threeFilled==false){
          this.threeFilled = true;
          element = event.srcElement.nextElementSibling;
         }
         if(event.target.name==="four"&&this.input4.nativeElement.value!==""&&this.oneFilled===true&&this.twoFilled==true&&this.threeFilled==true){
          this.fourFilled = true;
         }
      }
 
     if(element == null)
         return;
     else
         element.focus();
  }
  
}
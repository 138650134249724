import { ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: 'validation-message',
})
export class ValidationMessageDirective {
    @Input() errorCode: string;
    @Input() message: string;
    @ContentChild(TemplateRef, { static: true }) template: TemplateRef<any>;
    constructor() {}
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MomentPipe } from './moment.pipe';
import { YearsToNowPipe } from './years-to-now.pipe';
import { NumeralPipe } from './numeral.pipe';
import { TimeagoPipe } from './timeago.pipe';
import { CeilPipe } from './ceil.pipe';

@NgModule({
    declarations: [MomentPipe, NumeralPipe, YearsToNowPipe, TimeagoPipe, CeilPipe],
    imports: [CommonModule],
    exports: [MomentPipe, NumeralPipe, YearsToNowPipe, TimeagoPipe, CeilPipe],
})
export class SharedModule {}

import { ComponentsModule } from 'components/components.module';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from 'core/core.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DxDataGridModule } from 'devextreme-angular';
import { SharedModule } from 'shared/shared.module';
import { KeyboardShortcutsModule } from 'ng-keyboard-shortcuts';
import { ErrorInterceptor } from 'core/error.interceptor';
import { AuthModule, LogLevel, OidcSecurityService} from 'angular-auth-oidc-client';


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        DxDataGridModule,
        HttpClientModule,
        FormsModule,
        ComponentsModule,
        CoreModule,
        BrowserAnimationsModule,
        MatDialogModule,
        SharedModule,
        MatDatepickerModule,
        KeyboardShortcutsModule.forRoot(),
        AuthModule.forRoot({
            config: {
                 authority: 'https://eidentity.evolio.cz',
                redirectUrl: window.location.origin,
                postLogoutRedirectUri: window.location.origin,
                clientId: 'renato.client',
                scope: 'renato.api offline_access openid email profile',
                responseType: 'id_token token',
                disablePkce: true,
                silentRenew: true,
                useRefreshToken: true,
                silentRenewUrl: `${window.location.origin}/silent-renew.html`,
                logLevel: LogLevel.Debug,
                disableIdTokenValidation: true,
                autoUserInfo: false
            },
        }),
        RouterModule.forRoot([
            {
                loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
                path: 'dashboard',
            },
            {
                loadChildren: () => import('./modules/clients/clients.module').then((m) => m.ClientsModule),
                path: 'clients',
            },
            {
                loadChildren: () => import('./modules/contracts/contracts.module').then((m) => m.ContractsModule),
                path: 'contracts',
            },
            {
                loadChildren: () => import('./modules/overview/overview.module').then((m) => m.OverviewModule),
                path: 'overview',
            },
            {
                loadChildren: () => import('./modules/cash-register/cash-register.module').then((m) => m.CashRegisterModule),
                path: 'cash-register',
            },
            {
                loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
                path: 'settings',
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
        ]),
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        OidcSecurityService
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

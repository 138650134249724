import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalService } from './modal.service';
import { Session } from './session.service';
import { PinDialogComponent } from './dataservices/pin-dialog/pin-dialog.component';

@NgModule({
    declarations: [PinDialogComponent],
    imports: [CommonModule],
    providers: [ModalService, Session],
})
export class CoreModule {}

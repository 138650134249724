<div class="rModal theme-blue" style="position: relative;">
    <div class="rModal_header">
        <i class="icon-unlock"></i>
        <h3 class="">Zadejte prosím Váš <b>PIN</b></h3>
    </div>
    <div class="rModal_content">
        <p class="PIN_warning" *ngIf="context">Zadali jste neplatný PIN</p>
        <div *ngIf="!oneFilled" class="PIN_hider" style="left: 85px"><span *ngIf="!oneFilled&&!twoFilled&&!threeFilled&&!fourFilled" class="blinker"></span></div>
        <div *ngIf="!twoFilled" class="PIN_hider" style="left: 179px;"><span *ngIf="oneFilled&&!twoFilled&&!threeFilled&&!fourFilled" class="blinker"></span></div>
        <div *ngIf="!threeFilled" class="PIN_hider" style="left:273px"><span *ngIf="oneFilled&&twoFilled&&!threeFilled&&!fourFilled" class="blinker"></span></div>
        <div *ngIf="!fourFilled" class="PIN_hider" style="left: 367px"><span *ngIf="oneFilled&&twoFilled&&threeFilled&&!fourFilled" class="blinker"></span></div>

        <div *ngIf="oneFilled" class="PIN_hider" style="left: 85px">*</div>
        <div *ngIf="twoFilled" class="PIN_hider" style="left: 179px;">*</div>
        <div *ngIf="threeFilled" class="PIN_hider" style="left:273px">*</div>
        <div *ngIf="fourFilled" class="PIN_hider" style="left: 367px">*</div>
        <div id="form" class="PIN_form">
            <input name ="one" #num1 class="PIN_input" type="number" maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" (keyup)="onDigitInput($event)" onKeyPress="if(this.value.length==1) return false;" />
            <input name ="two" #num2 class="PIN_input" type="number" maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" (keyup)="onDigitInput($event)" onKeyPress="if(this.value.length==1) return false;"/>
            <input name ="three" #num3 class="PIN_input" type="number" maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" (keyup)="onDigitInput($event)" onKeyPress="if(this.value.length==1) return false;"/>
            <input name ="four" #num4 class="PIN_input" type="number" maxlength="1" size="1" min="0" max="9" pattern="[0-9]{1}" (keyup)="onDigitInput($event)" onKeyPress="if(this.value.length==1) return false;"/>
        </div>
    </div>
</div>

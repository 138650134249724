import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationMessages } from './validation-messages';

@Component({
    selector: 'app-validation-messages',
    templateUrl: './validation-messages.component.html',
    styleUrls: ['./validation-messages.component.scss'],
})
export class ValidationMessagesComponent implements OnInit {
    @Input() control: FormControl;
    @Input() validationMessages: ValidationMessages;

    constructor() {}

    ngOnInit(): void {}
}

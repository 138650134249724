import { Component, EventEmitter, forwardRef, Host, Input, OnInit, Optional, Output, SkipSelf } from '@angular/core';
import { ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValidationMessages } from '../validation-messages/validation-messages';
import { FormInputBase } from '../form-input-base';

const INPUT_VALUE_ACCESSOR: any = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumberInputComponent),
    multi: true,
};

@Component({
    selector: 'app-number-input',
    templateUrl: './number-input.component.html',
    styleUrls: ['./number-input.component.scss'],
    providers: [INPUT_VALUE_ACCESSOR],
    viewProviders: [{ provide: ValidationMessages, useClass: ValidationMessages }],
})
export class NumberInputComponent extends FormInputBase implements OnInit {
    public value: any;
    @Output() ngModelChange = new EventEmitter<any>();

    writeValue(obj: any): void {
        this.value = obj === undefined ? null : obj;
    }
    constructor(
        @Optional()
        @Host()
        @SkipSelf()
        controlContainer: ControlContainer,
        validationMessages: ValidationMessages
    ) {
        super(controlContainer, validationMessages);
    }

    ngOnInit(): void {}

    onInput(value: any) {
        this.raiseOnChange(value);
    }

    onBlur() {
        this.raiseOnTouched();
    }

    valueMinus() {
        if (this.value > 0) {
            this.value--;
            this.ngModelChange.next(this.value);
        }
    }
    valuePlus() {
        this.value++;
        this.ngModelChange.next(this.value);
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';

export interface IUser {
    email: string;
    email_verified: string;
    family_name: string;
    given_name: string;
    name: string;
    preferred_username: string;
    sub: string;
    id: number;
}

@Injectable()
export class Session {
    public user: IUser;
    public store: {
        pobocka: string;
        isAdmin: boolean;
    };
    constructor(private oicd: OidcSecurityService, private http: HttpClient, private router: Router) {}

    loadUser() {
        return new Promise((resolve, reject) => {
            this.router.events.subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    if (event.url.indexOf('unauthorized') != -1) {
                        resolve({});
                    }
                }
            });
            this.http.get('/api/core/me').subscribe((a: any) => {
                if (a) {
                    this.store = { pobocka: a.pobocka, isAdmin: a.isAdmin };
                    resolve({});
                } else {
                    this.router.navigate(['/dashboard/unauthorized']);
                    reject({});
                }
            });
        });
    }
    getStore(){
        return this.store.pobocka;
    }
    getStores() : Observable<any>{
       return this.http.get<any>('/api/core/pobocky');
    }
    async getPobocky(){
        const data : any = await this.http.get('/api/core/pobocky').toPromise();
        return data.cPobocky;
    }

    changePobocka(pobocka : string){
        this.http.get('/api/core/zmen-pobocku?pobocka='+pobocka).subscribe((a)=>{
            this.loadUser();
            location.reload();
        })
    }
}

<div class="rModal theme-yellow">
    <div class="rModal_header">
        <i class="icon-add"></i>
        <h3 class="">Záznam do pokladního deníku</h3>
    </div>
    <div class="rModal_content">
        <div class="rInput row mb-2">
            <label class="rInput_label col-3">Operace</label>
            <div class="col-4">
                <select *ngIf="cds" [(ngModel)]="dataObj.idOperace">
                    <option *ngFor="let o of cds.cDenikOperace" [ngValue]="o.idOperace">{{ o.nazev }}</option>
                </select>
            </div>
        </div>
        <div class="rInput row mb-2">
            <label class="rInput_label col-3">Částka</label>
            <div class="col-4">
                <input type="number" min="0" [(ngModel)]="dataObj.castka" />
            </div>
        </div>
        <div class="rInput row mb-2">
            <label class="rInput_label col-3">Popis</label>
            <div class="col-6">
                <input type="text" [(ngModel)]="dataObj.popis" />
            </div>
        </div>
    </div>
    <div class="rModal_footer" style="position: relative; min-height: 120px;">
        <button class="rBtn wide cancel" (click)="close()"style="position: absolute;
        right: 212px;">Zrušit</button>
        <button [disabled]="requestBlocker" class="rBtn big submit" (click)="submit()" style="position: absolute;
        right: 32px;">
            <span *ngIf="!requestBlocker">Uložit</span>
            <div class="lds-spinner" *ngIf="requestBlocker">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </button>
    </div>
</div>

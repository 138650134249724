import { Injectable, TemplateRef } from '@angular/core';
import { map } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { ComponentType } from '@angular/cdk/portal';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ModalService {

    constructor(private dialog: MatDialog) { }

    public open<T, D = any>(component: ComponentType<T> | TemplateRef<T>, data?: any, config: MatDialogConfig<D> = {}): Observable<any> {
        return this.dialog
            .open(component, {
                data: data !== null && data !== undefined ? data : null,
                width: config.width ? config.width : '700px',
                maxWidth: config.maxWidth ? config.maxWidth : '95vw',
                panelClass: config.panelClass ? config.panelClass : 'evModal_dialog',
                backdropClass: 'evModal_backdrop',
                autoFocus: config.autoFocus === false ? false : true
            })
            .afterClosed()
            .pipe(
                map(
                    (res) => {
                        return res;
                    },
                    (err) => {
                        return err;
                    }
                )
            );
    }
}

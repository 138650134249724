import { AfterViewInit, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl } from '@angular/forms';
import { ValidationMessageDirective } from './validation-messages/validation-message.directive';
import { ValidationMessages } from './validation-messages/validation-messages';

@Component({ template: '' })
export abstract class FormInputBase implements ControlValueAccessor, AfterViewInit {
    @Input() disabled = false;
    @Input() set formControl(value: FormControl) {
        this.control = value;
    }
    @Input() public set formControlName(value: string) {
        if (this.controlContainer.control) {
            this.control = this.controlContainer.control.get(value) as FormControl;
        }
    }

    @ContentChildren(ValidationMessageDirective) customMessages: QueryList<ValidationMessageDirective>;

    public control: FormControl;

    protected raiseOnChange: (value: any) => void;
    protected raiseOnTouched: () => void;

    constructor(readonly controlContainer: ControlContainer, public readonly validationMessages: ValidationMessages) {}

    abstract writeValue(obj: any): void;
    registerOnChange(fn: any): void {
        this.raiseOnChange = fn;
    }
    registerOnTouched(fn: any): void {
        this.raiseOnTouched = fn;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    ngAfterViewInit() {
        this.customMessages.forEach((m) => {
            this.validationMessages.messages.set(m.errorCode, () => m.template || m.message);
        });
    }
}

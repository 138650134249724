<div class="rModal theme-yellow">
    <div class="rModal_header">
        <i class="icon-add"></i>
        <h3 class="">Přidat dokument</h3>
    </div>
    <div class="rModal_content">
        <label class="dialogInput border input">
            <span class="">Nahrát dokument…</span>
            <input type="file" placeholder="">
            <i class="icon-folder"></i>
        </label>
    </div>
    <div class="rModal_footer">
        <button class="rBtn wide cancel" mat-dialog-close>Zrušit</button>
        <button class="rModal_submitBtn">Přidat</button>
    </div>
</div>

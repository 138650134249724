<input
    [value]="value"
    (input)="onInput(input.value)"
    (blur)="onBlur()"
    #input
    class="form-control"
    type="text"
    [placeholder]="placeholder ? placeholder : ''"
/>
<app-validation-messages [control]="control" [validationMessages]="validationMessages"></app-validation-messages>

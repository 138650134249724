import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, ɵɵtrustConstantResourceUrl } from '@angular/core';
import { ICashRegistry, ICashRegistryCDS, IDailyDeadline, IDayliDeadlines, IValue, IValueHodnoty, IVault } from 'models/cashregistry';
import {  of, Observable } from 'rxjs';
import { PinDialogComponent } from './pin-dialog/pin-dialog.component';
import { ModalService } from 'core/modal.service';

@Injectable({
    providedIn: 'root',
})
export class CashregisterService {
    constructor(private http: HttpClient, private modal: ModalService) {}

    getAll(page: number, itemsPerPage: number, idOperace?:number): Observable<{ count: number; values: ICashRegistry[] }> {
        if(idOperace!==undefined){
            let params: any = {
                top: itemsPerPage,
                skip: itemsPerPage * (page - 1),
                idOperace: idOperace
            }; 
            const opts = { params: new HttpParams({ fromObject: params }) };
            return this.http.get<{ count: number; values: ICashRegistry[] }>('/api/pokladnidenik', opts);
        }
        let params: any = {
            top: itemsPerPage,
            skip: itemsPerPage * (page - 1),
        };
        const opts = { params: new HttpParams({ fromObject: params }) };
        return this.http.get<{ count: number; values: ICashRegistry[] }>('/api/pokladnidenik', opts);
    }

    async confirmMoneyDelivery(id: number, wrongPIN: boolean = false){   
        let response = 0;
        await this.modal.open(PinDialogComponent,wrongPIN).toPromise().then(
            async (val) => {
                let headers = new HttpHeaders({ 'pin': val });
                await this.http.get('api/trezorovyDenik/potvrdit?idTrezorovyDenikPolozkaSeq=' + id, { headers }).toPromise()
                .then(
                    val => {
                                response=200;
                            })
                .catch(
                    err => { 
                                response = err.status;
                            });
            });
        if(response===405)
        return this.confirmMoneyDelivery(id,true);
        else
        return response;
    }

    getCDS(druh: string): Observable<ICashRegistryCDS> {
        return this.http.get<ICashRegistryCDS>('/api/pokladnidenik/cds?druh=' + druh);
    }
/* 
    postNewRecord(data) {
        return this.http.post('/api/pokladnidenik', data);
    } */

    async postNewRecord(data, wrongPIN: boolean = false){   
        let response = 0;
        await this.modal.open(PinDialogComponent,wrongPIN).toPromise().then(
            async (val) => {
                let headers = new HttpHeaders({ 'pin': val });
                await this.http.post('/api/pokladnidenik', data, { headers }).toPromise()
                .then(
                    val => {
                                response=200;
                            })
                .catch(
                    err => { 
                                response = err.status;
                            });
            });
        if(response===405)
        return this.postNewRecord(data,true);
        else
        return response;
    }
/* 
    postNewDailyDeadline(data) {
        return this.http.post('/api/pokladnidenik/uzaverka', data);
    } */

    async postNewDailyDeadline(data, wrongPIN: boolean = false){   
        let response = 0;
        let tmp;
        await this.modal.open(PinDialogComponent,wrongPIN).toPromise().then(
            async (val) => {
                let headers = new HttpHeaders({ 'pin': val });
                await this.http.post('/api/pokladnidenik/uzaverka', data, { headers }).toPromise()
                .then(
                    val => {
                                tmp = val;
                                response=200;
                            })
                .catch(
                    err => { 
                                tmp = err;
                                response = err.status;
                            });
            });
        if(response===405)
        return this.postNewDailyDeadline(data,true);
        else
        return tmp;
    }

    getDailyDeadlineById(id:number,pobocka:string): Observable<IDailyDeadline> {
        return this.http.get<IDailyDeadline>('/api/pokladnidenik/uzaverka/detail?idPokladniDenikUzaverkaSeq=' + id + '&pobocka=' + pobocka);
    }

   /*  dailyDeadlineStorno(idPokladniDenikUzaverkaSeq, poznamka) {
        return this.http.post('/api/pokladnidenik/uzaverka-storno', {
            idPokladniDenikUzaverkaSeq: idPokladniDenikUzaverkaSeq,
            poznamka: poznamka,
        });
    } */

    async dailyDeadlineStorno(idPokladniDenikUzaverkaSeq, poznamka, wrongPIN: boolean = false){   
        let response = 0;
        await this.modal.open(PinDialogComponent,wrongPIN).toPromise().then(
            async (val) => {
                let headers = new HttpHeaders({ 'pin': val });
                await this.http.post('/api/pokladnidenik/uzaverka-storno', {
                    idPokladniDenikUzaverkaSeq: idPokladniDenikUzaverkaSeq,
                    poznamka: poznamka,
                }, { headers }).toPromise()
                .then(
                    val => {
                                response=200;
                            })
                .catch(
                    err => { 
                                response = err.status;
                            });
            });
        if(response===405)
        return this.dailyDeadlineStorno(idPokladniDenikUzaverkaSeq, poznamka,true);
        else
        return response;
    }


    getDailyDealinesList(pobocka = 'ZAKAZKOVYVYVOJ'): Observable<{ count: number; values: IDayliDeadlines[] }> {
        return this.http.get<{ count: number; values: IDayliDeadlines[] }>('/api/pokladnidenik/uzaverka');
    }

    getLastDeadlines(page: number, itemsPerPage: number): Observable<{ count: number; values: any }> {
        let params: any = {
            top: itemsPerPage,
            skip: itemsPerPage * (page - 1),
        };
        const opts = { params: new HttpParams({ fromObject: params }) };

        return this.http.get<{ count: number; values: any }>('/api/pokladnidenik/uzaverka', opts);
    }

    getValue(page: number, itemsPerPage: number) : Observable<{ count: number; values: IValue[] }>{
        let params: any = {
            top: itemsPerPage,
            skip: itemsPerPage * (page - 1),
        };
        const opts = { params: new HttpParams({ fromObject: params }) };
        return this.http.get<{ count: number; values: IValue[] }>('/api/trezorovydenik', opts);
    }

    getValueCDS() {
        return this.http.get<{ cDenikOperace: any[]; cTrezorovyDenikBalicky: IValueHodnoty[] }>('/api/trezorovydenik/cds');
    }

    getBranches() {
        return this.http.get<{ branches: any[]}>('/api/core/pobocky');
    }
    getVaultContents(){
        return this.http.get<{ vault: IVault}>('api/prehled/trezorovyDenik');
    }

    getVaultContentsPobocka(){
        return this.http.get<{ vault: IVault}>('api/prehled/trezorovyDenik-pobocka');
    }
    /* postValueTransfer(zPokladny: boolean, cilovaPobocka: string, idOperace: number, castkyJson: string) {
        return this.http.post('/api/trezorovydenik', {
            cilovaPobocka : cilovaPobocka,
            idOperace: idOperace,
            zPokladny: zPokladny,
            castkyJson: castkyJson,
            poznamka: '',
        });
    } */

    async postValueTransfer(zPokladny: boolean, cilovaPobocka: string, idOperace: number, castkyJson: string, wrongPIN: boolean = false){   
        let response;
        await this.modal.open(PinDialogComponent,wrongPIN).toPromise().then(
            async (val) => {
                let headers = new HttpHeaders({ 'pin': val });
                await this.http.post('/api/trezorovydenik', {
                    cilovaPobocka : cilovaPobocka,
                    idOperace: idOperace,
                    zPokladny: zPokladny,
                    castkyJson: castkyJson,
                    poznamka: '',
                }, { headers }).toPromise()
                .then(
                    val => {
                                response=200;
                            })
                .catch(
                    err => { 
                                response = err;
                            });
            });
        if(response.status===405)
        return this.postValueTransfer(zPokladny,cilovaPobocka,idOperace,castkyJson,true);
        else
        return response;
    }


}

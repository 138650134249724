import { Injectable } from '@angular/core'
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private history: string[] = []
  private menuItem: number = 0;

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
      }
    })
  }

  getHistory(){
    return this.history;
  }

  getLastPage(){
    return this.history[this.history.length-2];
  }

  setMenuItem(item : number){
    this.menuItem=item;
  }

  getMenuItem(){
    return this.menuItem;
  }

  

  back(): void {
    this.history.pop()
    if (this.history.length > 0) {
      if(this.history[this.history.length-1].includes('/clients') && this.history[this.history.length-1].includes('-1')){
        this.router.navigateByUrl('/clients')
      }
      if(this.history[this.history.length-1].includes('/contracts')&&this.history[this.history.length-1].includes('-1')){
        this.router.navigateByUrl('/contracts')
      } 
      else {
        this.location.back();
      }
    } 
    else {
      this.router.navigateByUrl('/')
    }
  }
}
<div class="rModal theme-yellow">
    <div class="rModal_header">
        <i class="icon-search"></i>
        <h3 class="">Vyber subjekt</h3>
        <div class="rSearch rSearch-outline ml-4">
            <i class="icon-search"></i>
            <input type="text" placeholder="Vyhledat osobu…" />
        </div>
    </div>
    <div class="rModal_content">
        <div class="rTable_scroll">
            <div class="rTable">
                <div class="rTable_line " *ngFor="let p of subjects" (click)="selectSubject(p)">
                    <div class="rTable_cell">{{ (p.jmeno || '') + ' ' + (p.prijmeni || '') }}</div>
                </div>
            </div>
        </div>
        <div class="text-center">
            <button class="rBtn" (click)="loadMore()">Load more</button>
        </div>
    </div>
    <div class="rModal_footer">
        <button class="rBtn wide cancel" (click)="close()">Zrušit</button>
        <button class="rModal_submitBtn" (click)="submit()">Vybrat</button>
    </div>
</div>
